import { MessagesArrayType, MessageType } from '../types';
import { CognitoUserSession } from 'amazon-cognito-identity-js';
import React, { useState } from 'react';
import Message from './Message';
import UnHandledActionsAlert from './UnHandledActionsAlert';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelopeOpen } from '@fortawesome/free-solid-svg-icons';
import GenericErrorAlert from './GenericErrorAlert';

function Inbox(props: {
    messages: MessagesArrayType;
    session?: CognitoUserSession;
    onMessageClick: (message: MessageType) => void;
    onActionClick: (
        action: string,
        message: MessageType,
        body: Record<string, any> | null,
    ) => Promise<void>;
    error: boolean;
}) {
    const [selectedMessage, setSelectedMessage] = useState<
        MessageType | undefined
    >(undefined);

    const render = () => {
        if (
            props.session &&
            props.session.isValid() &&
            props.messages.length > 0
        ) {
            const session: CognitoUserSession = props.session;
            const unhandledMessages = props.messages.filter(
                (m) =>
                    m.actionTaken === null && m.actions && m.actions.length > 0,
            );
            return (
                <div className={'mx-auto w-9/12 flex flex-col m-6 space-y-5'}>
                    <div className={'stack'}>
                        {props.error && <GenericErrorAlert />}
                        <UnHandledActionsAlert
                            unhandledActions={unhandledMessages.length}
                            targetMessage={
                                unhandledMessages.length > 0
                                    ? unhandledMessages[0]
                                    : undefined
                            }
                            clickHandler={setSelectedMessage}
                        />
                    </div>
                    <ul className="flex flex-col grow space-y-1 pb-10 overflow-x-visible">
                        {props.messages.map((item, index) => (
                            <li
                                className={`shrink-0 bg-base-100 w-full rounded-md border-stone-300 overflow-x-visible mb-2 min-h-min p-3 divide-solid divide-y border
                                ${
                                    item.messageId ===
                                    selectedMessage?.messageId
                                        ? 'shadow-md'
                                        : 'shadow-sm hover:shadow'
                                }
                                `}
                                key={item.messageId}
                            >
                                <Message
                                    message={item}
                                    session={session}
                                    selected={
                                        item.messageId ===
                                        selectedMessage?.messageId
                                    }
                                    onActionClick={props.onActionClick}
                                    messageSelector={messageClicked}
                                />
                            </li>
                        ))}
                    </ul>
                </div>
            );
        } else {
            return (
                <div className={`mx-auto w-9/12 h-full m-6 flex flex-col`}>
                    {props.error && (
                        <div className={'stack'}>
                            <GenericErrorAlert />
                        </div>
                    )}
                    <div
                        className={
                            'h-4/6 flex flex-col justify-center items-center space-y-5'
                        }
                    >
                        <FontAwesomeIcon
                            icon={faEnvelopeOpen}
                            className={'h-10 w-10 text-stone-400'}
                        />
                        <div className={'text-lg text-stone-400 font-Lora'}>
                            {props.error
                                ? 'Det gick inte att hämta dina meddelanden just nu...'
                                : 'Du har inga meddelanden just nu...'}
                        </div>
                    </div>
                </div>
            );
        }
    };

    const messageClicked = (message: MessageType) => {
        props.onMessageClick(message);
        if (selectedMessage?.messageId === message.messageId) {
            setSelectedMessage(undefined);
        } else {
            setSelectedMessage(message);
        }
    };

    return render();
}

export default Inbox;
