import React from 'react';
import { faXmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export default function GenericErrorAlert() {
    return (
        <div
            className="alert flex-row shadow-lg border-2 border-error bg-base-100 text-center justify-center shadow-md w-full"
            key={`${Math.random() * 1000}`}
        >
            <div>
                <FontAwesomeIcon icon={faXmark} />
                <div>
                    <div className="text-xs text-slate-700">
                        Något gick fel! Vänligen kontakta oss på
                        support@invoier.com
                    </div>
                </div>
            </div>
        </div>
    );
}
