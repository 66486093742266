import React, { useCallback, useEffect, useState } from 'react';
import * as t from 'io-ts';
import {
    AuthenticationDetails,
    CognitoUser,
    CognitoUserPool,
    CognitoUserSession,
    ICognitoUserData,
    ICognitoUserPoolData,
} from 'amazon-cognito-identity-js';
import { Base64 } from 'js-base64';
import urlcat from 'urlcat';
import jwtDecode from 'jwt-decode';
import { pipe } from 'fp-ts/function';
import { getOrElse } from 'fp-ts/Either';
import { MessagesArray, MessageType } from './types';
import Inbox from './Messages/Inbox';
import { ReactComponent as InvoierFullLogo } from './assets/icons/inline-logo.svg';
import Spinner from './Misc/Spinner/Spinner';
// import { faLinkedin } from '@fortawesome/free-brands-svg-icons';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import log, { LogLevel } from './utils/logging';

const MessagesResponse = t.type({
    messages: MessagesArray,
});

function App() {
    const API_URL = '/api';
    const POOL_DATA: ICognitoUserPoolData = {
        UserPoolId: process.env.REACT_APP_USER_POOL_ID
            ? process.env.REACT_APP_USER_POOL_ID
            : 'N/A',
        ClientId: process.env.REACT_APP_CLIENT_ID
            ? process.env.REACT_APP_CLIENT_ID
            : 'N/A',
    };

    const [userPool] = useState(new CognitoUserPool(POOL_DATA));

    const [authAttempted, setAuthAttempted] = useState(false);
    const [authFailed, setAuthFailed] = useState(false);
    const [initialLoadCompleted, setInitialLoadCompleted] = useState(false);
    const [session, setSession] = useState<CognitoUserSession | undefined>(
        undefined,
    );

    const { email, token } = extractEmailAndToken();

    const USER_DATA: ICognitoUserData = {
        Username: email,
        Pool: userPool,
    };

    const [user] = useState(new CognitoUser(USER_DATA));

    const [messages, setMessages] = useState<t.TypeOf<typeof MessagesArray>>(
        [],
    );
    const [genericError, setGenericError] = useState(false);

    const [successMessage, setSuccessMessage] = useState(false);

    useEffect(() => {
        user.setAuthenticationFlowType('CUSTOM_AUTH');
        user.initiateAuth(new AuthenticationDetails({ Username: email }), {
            onSuccess: function (session) {
                setSession(session);
                setAuthAttempted(true);
            },
            onFailure: function (err) {
                setSession(undefined);
                setAuthAttempted(true);
                setAuthFailed(true);
                setGenericError(true);
            },
            customChallenge: function (challengeParameters) {
                if (challengeParameters['type'] === 'token') {
                    // User authentication depends on challenge response
                    user.sendCustomChallengeAnswer(token, this);
                } else {
                    setAuthAttempted(true);
                    setAuthFailed(true);
                    setGenericError(true);
                    throw Error('Received unexpected challenge type.');
                }
            },
        });
    }, [user, email, token]);

    const loadMessages = useCallback(async () => {
        const jwtToken = session?.getIdToken().getJwtToken();
        try {
            if (jwtToken !== undefined) {
                const { accountId } = jwtDecode(jwtToken) as any;
                const url = urlcat(API_URL, '/accounts/:accountId/messages', {
                    accountId,
                });
                const response = await fetch(url, {
                    method: 'GET',
                    credentials: 'include',
                    headers: {
                        Authorization: `Bearer ${jwtToken}`,
                    },
                    mode: 'cors',
                });
                if (response.ok) {
                    const messages = await decodeJSONResponse<
                        typeof MessagesResponse,
                        t.TypeOf<typeof MessagesResponse>
                    >(response, MessagesResponse);

                    setMessages(messages.messages);
                } else {
                    log({
                        level: LogLevel.ERROR,
                        message: `Expected loadMessages response to be 200, was ${response.status}: ${response.statusText}`,
                        service: 'App',
                    });
                    setGenericError(true);
                }
            } else {
                log({
                    level: LogLevel.ERROR,
                    message: `JWT was undefined!`,
                    service: 'App',
                });
                setGenericError(true);
            }
        } catch (err) {
            log({
                level: LogLevel.ERROR,
                message: `Unexpected error when loading messages: ${err}`,
                service: 'App',
            });
            setGenericError(true);
        }
        setInitialLoadCompleted(true);
    }, [API_URL, session]);

    useEffect(() => {
        if (session) {
            refreshToken(session, user).then(() => loadMessages());
        }
    }, [session, user]);

    const onActionClick = async (
        action: string,
        message: MessageType,
        body: Record<string, any> | null,
    ): Promise<void> => {
        await refreshToken(session, user);
        return new Promise(async (resolve, reject) => {
            try {
                const jwtToken = session?.getIdToken().getJwtToken();
                if (jwtToken !== undefined) {
                    const { accountId } = jwtDecode(jwtToken) as any;
                    const url = urlcat(
                        API_URL,
                        '/accounts/:accountId/messages/:messageId/actions/:action',
                        {
                            accountId,
                            messageId: message.messageId,
                            action: action,
                        },
                    );
                    const response = await fetch(url, {
                        method: 'POST',
                        credentials: 'include',
                        headers: {
                            Authorization: `Bearer ${jwtToken}`,
                        },
                        mode: 'cors',
                        body: body ? JSON.stringify(body) : '{}',
                    });

                    if (response.ok) {
                        const tmpMessages = [...messages];
                        const index = tmpMessages.findIndex(
                            (p) => p.messageId === message.messageId,
                        );
                        if (index > -1) {
                            tmpMessages[index] = {
                                ...tmpMessages[index],
                                read: true,
                                actionTaken: action,
                            };
                            setMessages(tmpMessages);
                        }
                        setSuccessMessage(true);
                        resolve();
                    } else {
                        log({
                            level: LogLevel.ERROR,
                            message: `Expected response from post of action to be 200, was ${response.status}: ${response.statusText}`,
                            service: 'App',
                        });
                        setGenericError(true);
                        reject();
                    }
                } else {
                    log({
                        level: LogLevel.ERROR,
                        message: `JWT was undefined!`,
                        service: 'App',
                    });
                    setGenericError(true);
                    reject();
                }
            } catch (err) {
                log({
                    level: LogLevel.ERROR,
                    message: `Unexpected error when posting action: ${err}`,
                    service: 'App',
                });
                setGenericError(true);
                reject(err);
            }
        });
    };

    const updateReadStatus = async (message: MessageType) => {
        await refreshToken(session, user);
        try {
            const jwtToken = session?.getIdToken().getJwtToken();
            if (jwtToken !== undefined) {
                const { accountId } = jwtDecode(jwtToken) as any;
                const url = urlcat(
                    API_URL,
                    '/accounts/:accountId/messages/:messageId/actions/read',
                    {
                        accountId,
                        messageId: message.messageId,
                    },
                );
                const response = await fetch(url, {
                    method: 'POST',
                    credentials: 'include',
                    headers: {
                        Authorization: `Bearer ${jwtToken}`,
                    },
                    mode: 'cors',
                });

                if (response.ok) {
                    const tmpMessages = [...messages];
                    const index = tmpMessages.findIndex(
                        (p) => p.messageId === message.messageId,
                    );
                    if (index > -1) {
                        tmpMessages[index] = {
                            ...tmpMessages[index],
                            read: true,
                        };
                        setMessages(tmpMessages);
                    }
                } else {
                    log({
                        level: LogLevel.ERROR,
                        message: `Expected response from post of read to be 200, was ${response.status}: ${response.statusText}`,
                        service: 'App',
                    });
                }
            } else {
                log({
                    level: LogLevel.ERROR,
                    message: `JWT was undefined!`,
                    service: 'App',
                });
            }
        } catch (err) {
            log({
                level: LogLevel.ERROR,
                message: `Unexpected error when posting read: ${err}`,
                service: 'App',
            });
        }
    };

    return (
        <div className="App bg-accent h-screen flex flex-col">
            <header className="py-4 z-10 flex justify-center items-center bg-base-100 shadow-md space-x-2">
                <a
                    href={'https://invoier.com'}
                    className={
                        'w-fit h-fit items-center justify-center flex inline-block'
                    }
                >
                    <InvoierFullLogo
                        fill="slate-900"
                        className={
                            'relative h-7 bg-transparent pointer-events-none'
                        }
                    />
                </a>
            </header>
            <div
                className={'flex-1 overflow-y-auto flex space-around flex-col'}
            >
                {!(authAttempted && (initialLoadCompleted || authFailed)) && (
                    <div className={'basis-full'}>
                        <Spinner message={'Läser in dina meddelanden...'} />
                    </div>
                )}
                {authAttempted && (initialLoadCompleted || authFailed) && (
                    <Inbox
                        messages={messages.filter(
                            (f) => f.actionTaken === null,
                        )}
                        session={session}
                        onMessageClick={updateReadStatus}
                        onActionClick={onActionClick}
                        error={genericError}
                    />
                )}
            </div>

            {successMessage && renderSuccessMsg(setSuccessMessage)}

            <footer className="py-4 flex border-t justify-center items-center bg-base-100 shadow-md space-x-5">
                {/* <a
                    href={'https://www.linkedin.com/company/invoier/'}
                    className={
                        'w-fit h-fit items-center justify-center flex inline-block'
                    }
                >
                    <FontAwesomeIcon
                        icon={faLinkedin}
                        className={
                            'relative h-7 bg-transparent pointer-events-none'
                        }
                    />
                </a>*/}
            </footer>
        </div>
    );
}

export const renderSuccessMsg = (onClickHandler: (arg0: boolean) => void) => {
    return (
        <div className="absolute bg-white/30 top-0 bottom-0 left-0 right-0 z-20 backdrop-blur-md  flex flex-col justify-center items-center">
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width="80"
                height="80"
                viewBox="0 0 20 20"
                fill="currentColor"
            >
                <path
                    fillRule="evenodd"
                    fill="#65a30d"
                    d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                    clipRule="evenodd"
                />
            </svg>
            <div className="font-Lora font-bold md:text-2xl p-4 text-center max-w-xs">
                Tack för hjälpen. Du kan nu stänga meddelandet
            </div>
            <button
                className={'btn btn-primary mt-4'}
                onClick={() => onClickHandler(false)}
            >
                STÄNG
            </button>
        </div>
    );
};

export default App;

function extractEmailAndToken(): { email: string; token: string } {
    const queryParamsProxy: any = new Proxy(
        new URLSearchParams(window.location.search),
        {
            get: (searchParams, prop: string) => searchParams.get(prop),
        },
    );
    const base64_token: string | null = queryParamsProxy.token;
    if (!base64_token) {
        log({
            level: LogLevel.ERROR,
            message: 'No token found in query!',
            service: 'App',
        });
        return {
            email: 'N/A',
            token: 'N/A',
        };
    }
    const decoded = Base64.decode(base64_token);
    const split = decoded.split('_');
    return {
        email: split[0],
        token: split[1],
    };
}

async function decodeJSONResponse<T extends t.Decoder<unknown, R>, R>(
    response: Response,
    decoder: T,
): Promise<R> {
    const responseBody = await response.json();

    return pipe(
        decoder.decode(responseBody),
        getOrElse<t.Errors, R>((e: t.Errors) => {
            throw e;
        }),
    );
}

async function refreshToken(
    session: CognitoUserSession | undefined,
    user: CognitoUser,
): Promise<void> {
    return new Promise((resolve, reject) => {
        if (session) {
            if (session.getIdToken().getExpiration() <= Date.now() / 1000 + 5) {
                user.refreshSession(
                    session.getRefreshToken(),
                    (err: any, res: any) => {
                        if (err) {
                            reject(err);
                        }
                        resolve();
                    },
                );
            } else {
                resolve();
            }
        } else {
            reject('No session found');
        }
    });
}
