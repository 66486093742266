import React, { useEffect } from 'react';

export default function ClickOutsideHook(
    ref: React.RefObject<any>,
    callback: () => void,
) {
    useEffect(() => {
        function handleClickOutside(event: Event) {
            if (
                ref.current &&
                event?.target &&
                event.target instanceof Node &&
                !ref.current.contains(event.target)
            ) {
                callback();
            }
        }

        [`click`, `touchstart`].forEach((eventType) => {
            document.addEventListener(eventType, handleClickOutside);
        });

        return () => {
            [`click`, `touchstart`].forEach((eventType) => {
                document.removeEventListener(eventType, handleClickOutside);
            });
        };
    }, [ref, callback]);
}
