export enum LogLevel {
    'TRACE',
    'DEBUG',
    'INFO',
    'WARNING',
    'ERROR',
    'FATAL',
}

export interface LogEvent {
    service: string;
    message: string;
    level: LogLevel;
}

export default function log(logEvent: LogEvent): void {
    if (process.env.REACT_APP_ENVIRONMENT !== 'prod') {
        console.log(
            `[${logEvent.level}]: ${logEvent.service} - ${logEvent.message}`,
        );
    }
}
