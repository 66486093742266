import * as t from 'io-ts';

const Action = t.type({
    type: t.string,
    default: t.boolean,
});

type ActionType = t.TypeOf<typeof Action>;

const Asset = t.type({
    type: t.string,
    url: t.string,
});

type AssetType = t.TypeOf<typeof Asset>;

const Message = t.type({
    messageId: t.string,
    content: t.string,
    title: t.string,
    lang: t.string,
    read: t.boolean,
    actionTaken: t.union([t.string, t.null]),
    accountId: t.string,
    actions: t.union([t.array(Action), t.undefined]),
    type: t.string,
    assets: t.array(Asset),
});

type MessageType = t.TypeOf<typeof Message>;

const MessagesArray = t.array(Message);

type MessagesArrayType = t.TypeOf<typeof MessagesArray>;

export { Action, Message, MessagesArray, Asset };

export type { ActionType, MessageType, MessagesArrayType, AssetType };
