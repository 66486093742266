import { MessageType } from '../types';
import { CognitoUserSession } from 'amazon-cognito-identity-js';
import ClickOutsideHook from '../hooks/ClickOutsideHook';
import { useRef } from 'react';
import MessageHeader from './MessageHeader';
import { Formik, Field } from 'formik';

function Message(props: {
    message: MessageType;
    session: CognitoUserSession;
    selected: boolean;
    onActionClick: (
        action: string,
        message: MessageType,
        body: Record<string, any> | null,
    ) => Promise<void>;
    messageSelector: (message: MessageType) => void;
}) {
    const openMessageRef = useRef(null);
    ClickOutsideHook(openMessageRef, () =>
        props.messageSelector(props.message),
    );

    const render = () => {
        const invoiceAsset = props.message.assets.find(
            (f) => f.type === 'invoice',
        );
        if (props.selected) {
            return (
                <div
                    ref={openMessageRef}
                    className="divide-y-[1px] divide-grey"
                >
                    <MessageHeader
                        messageSelector={props.messageSelector}
                        message={props.message}
                        selected={props.selected}
                    />
                    <div className="md:divide-x-[1px] divide-y-[1px]  md:divide-y-[0] divide-grey pt-2 md:grid md:grid-cols-2 gap-4 font-normal">
                        <div
                            className={
                                'pb-2  md:col-span-1 flex flex-col justify-between h-full pb-8 md:pb-0'
                            }
                        >
                            <div
                                className={
                                    'flex-none min-h-12 pl-2 mb-4 text-slate-700 text-xs md:text-sm whitespace-pre-line'
                                }
                            >
                                {props.message.content}
                            </div>
                            {invoiceAsset && (
                                <a
                                    href={invoiceAsset.url}
                                    target={'_blank'}
                                    rel={'noreferrer'}
                                    className={
                                        'w-fit h-fit group items-center justify-center flex inline-block border border-stone-300 pl-3 pr-3 pt-2 pb-2 rounded-md'
                                    }
                                    download
                                >
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        className="h-5 w-4 text-slate-700 group-hover:text-slate-400 rotate-[315deg] b-0 stroke-primary"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                        stroke="currentColor"
                                    >
                                        <path
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            strokeWidth={1.5}
                                            shapeRendering="geometricPrecision"
                                            d="M15.172 7l-6.586 6.586a2 2 0 102.828 2.828l6.414-6.586a4 4 0 00-5.656-5.656l-6.415 6.585a6 6 0 108.486 8.486L20.5 13"
                                        />
                                    </svg>
                                    <span
                                        className={
                                            'text-sm ml-2 text-slate-700 group-hover:text-primary font-semibold'
                                        }
                                    >
                                        Ladda ned faktura
                                    </span>
                                </a>
                            )}
                        </div>
                        {renderForm(props.message, props.onActionClick)}
                    </div>
                </div>
            );
        } else {
            return (
                <MessageHeader
                    messageSelector={props.messageSelector}
                    message={props.message}
                    selected={props.selected}
                />
            );
        }
    };

    return render();
}

export default Message;

export const renderForm = (
    message: MessageType,
    onActionClick: (
        action: string,
        message: MessageType,
        body: Record<string, any> | null,
    ) => void,
) => {
    if (message.actions && message.actionTaken === null) {
        switch (message.type) {
            case 'DA_EMAIL':
                return (
                    <div className="md:pl-4">
                        <Formik
                            initialValues={{
                                name: '',
                                action: message.actions!.find((p) => p.default)!
                                    .type,
                                title: '',
                            }}
                            onSubmit={async (values, { setSubmitting }) => {
                                await onActionClick(
                                    values.action,
                                    message,
                                    values,
                                );
                                setSubmitting(false);
                            }}
                            validate={(values) => {
                                const errors: any = {};
                                if (!values.name) {
                                    errors.name = 'Required';
                                }
                                if (!values.title) {
                                    errors.title = 'Required';
                                }
                                return errors;
                            }}
                        >
                            {({
                                values,
                                errors,
                                touched,
                                handleChange,
                                handleBlur,
                                handleSubmit,
                                isSubmitting,
                                /* and other goodies */
                            }) => (
                                <form
                                    onSubmit={handleSubmit}
                                    className="flex flex-col justify-between h-full max-h-480"
                                >
                                    <div>
                                        <div
                                            className={
                                                'form-control w-full pb-4'
                                            }
                                        >
                                            <input
                                                className={`h-10 pl-0 pr-0 input input-bordered border-secondary border-t-0 border-l-0 border-r-0 rounded-none  w-full focus:outline-none ${
                                                    errors.name && touched.name
                                                        ? 'input-error border-red-500'
                                                        : ''
                                                }`}
                                                type="text"
                                                name="name"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                value={values.name}
                                            />
                                            <label className="label pb-0 font-bold">
                                                <span className="label-text">
                                                    Namn
                                                </span>
                                            </label>
                                            {errors.name && touched.name && (
                                                <label className="label pt-0">
                                                    <span className="label-text-alt text-red-600">
                                                        {errors.name}
                                                    </span>
                                                </label>
                                            )}
                                        </div>

                                        <div
                                            className={
                                                'form-control w-full pb-4'
                                            }
                                        >
                                            <input
                                                className={`h-10 pl-0 pr-0 input input-bordered border-secondary border-t-0 border-l-0 border-r-0 rounded-none  w-full focus:outline-none ${
                                                    errors.title &&
                                                    touched.title
                                                        ? 'input-error border-red-500'
                                                        : ''
                                                }`}
                                                type="text"
                                                name="title"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                value={values.title}
                                            />
                                            <label className="label pb-0">
                                                <span className="label-text font-bold">
                                                    Titel
                                                </span>
                                            </label>
                                            {errors.title && touched.title && (
                                                <label className="label pt-0">
                                                    <span className="label-text-alt text-red-600">
                                                        {errors.title}
                                                    </span>
                                                </label>
                                            )}
                                        </div>

                                        <div className={'form-control w-full '}>
                                            <Field
                                                component="select"
                                                className={
                                                    'h-10 select select-bordered w-full input-bordered border-secondary border-t-0 border-l-0 border-r-0 rounded-none pl-0 pr-0'
                                                }
                                                name="action"
                                                multiple={false}
                                                defaultValue={
                                                    message.actions!.find(
                                                        (p) => p.default,
                                                    )!.type
                                                }
                                            >
                                                {message
                                                    .actions!.sort((a, b) =>
                                                        a.default ? 1 : -1,
                                                    )
                                                    .map((a) => (
                                                        <option
                                                            value={a.type}
                                                            key={`message-option-${a.type}`}
                                                        >
                                                            {translateAction(
                                                                a.type,
                                                            )}
                                                        </option>
                                                    ))}
                                            </Field>
                                            <label className="label font-bold">
                                                <span className="label-text">
                                                    Svar
                                                </span>
                                            </label>
                                        </div>
                                    </div>

                                    <div className="text-right">
                                        <button
                                            className={
                                                'btn btn-primary mt-4 w-1/2'
                                            }
                                            type="submit"
                                            disabled={
                                                isSubmitting ||
                                                message.actionTaken !== null
                                            }
                                        >
                                            <div
                                                className={
                                                    'flex flex-row items-center'
                                                }
                                            >
                                                {isSubmitting && (
                                                    <svg
                                                        className="animate-spin ml-1 mr-3 h-5 w-5"
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        fill="none"
                                                        viewBox="0 0 24 24"
                                                    >
                                                        <circle
                                                            className="opacity-25"
                                                            cx="12"
                                                            cy="12"
                                                            r="10"
                                                            stroke="currentColor"
                                                            strokeWidth="4"
                                                        />
                                                        <path
                                                            className="opacity-75"
                                                            fill="currentColor"
                                                            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                                                        />
                                                    </svg>
                                                )}
                                                <span>Skicka</span>
                                            </div>
                                        </button>
                                    </div>
                                </form>
                            )}
                        </Formik>
                    </div>
                );
            default:
                return <div />;
        }
    }
    return <div />;
};

export const translateAction = (actionType: string): string => {
    switch (actionType) {
        case 'accept_da':
            return 'Godkänn';
        case 'reject_da':
            return 'Avvisa';
        default:
            return actionType;
    }
};
