import { MessageType } from '../types';
import { useState } from 'react';

function UnHandledActionsAlert(props: {
    unhandledActions: number;
    targetMessage: MessageType | undefined;
    clickHandler: (message: MessageType) => void;
}) {
    const [dismissed, setDismissed] = useState(false);

    return (
        <div>
            {!dismissed && props.unhandledActions > 0 && props.targetMessage && (
                <div className="alert sm:flex-row shadow-lg bg-primary">
                    <div>
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            className="stroke-info flex-shrink-0 w-6 h-6 stroke-base-100"
                        >
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                                d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                            />
                        </svg>
                        <div>
                            <div className="text-md text-base-100">
                                Du har {props.unhandledActions} ohanter
                                {props.unhandledActions > 1 ? 'ade' : 'at'}{' '}
                                meddelande
                                {props.unhandledActions > 1 ? 'n' : ''} som{' '}
                                väntar!
                            </div>
                        </div>
                    </div>
                    <div className="flex-none">
                        <button
                            className="btn btn-sm bg-base-100 hover:bg-accent-focus border-0 text-primary font-normal"
                            onClick={() => {
                                setDismissed(true);
                                props.clickHandler(props.targetMessage!);
                            }}
                        >
                            Hantera
                        </button>
                    </div>
                </div>
            )}
        </div>
    );
}

export default UnHandledActionsAlert;
